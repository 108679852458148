export const dateToYYYYmmDD = (ISOdate: string | Date): string => {
  const date = ISOdate instanceof Date ? ISOdate : new Date(ISOdate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const dateToYYYYmmDDHHmm = (ISOdate: string | Date): string => {
  const date = ISOdate instanceof Date ? ISOdate : new Date(ISOdate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const hour = String(date.getHours());
  const minute = String(date.getMinutes());

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
};

export const startMonthDate = (date: string | Date): string => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return dateToYYYYmmDD(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1));
};

export const endMonthDate = (date: string | Date): string => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return dateToYYYYmmDD(new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0));
};

export const lastDayOfMonth = (date: string | Date): number => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  return new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0).getDate();
};

export const subtractMonths = (date: string | Date, count: number): Date => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  parsedDate.setMonth(parsedDate.getMonth() - count);
  return parsedDate;
};

export const isToday = (someDate: Date, UTCFormat: boolean = false) => {
  const today = new Date()

  return isEqualDates(today, someDate, UTCFormat)
}

export const isEqualDates = (date1: Date, date2: Date, UTCFormat: boolean = false) => {
  const parsedDate1 = new Date(date1)
  const parsedDate2 = new Date(date2)

  if (UTCFormat) {
    return parsedDate1.getUTCDate() === parsedDate2.getUTCDate() &&
      parsedDate1.getUTCMonth() === parsedDate2.getUTCMonth() &&
      parsedDate1.getUTCFullYear() === parsedDate2.getUTCFullYear()
  }

  return parsedDate1.getDate() === parsedDate2.getDate() &&
    parsedDate1.getMonth() === parsedDate2.getMonth() &&
    parsedDate1.getFullYear() === parsedDate2.getFullYear()
}

export const getLastDays = (days: number) => {
  const result = [];
  for (let i = 0; i < days; i++) {
    const d = new Date();
    d.setDate(d.getDate() - i);
    result.push( dateToYYYYmmDD(d) )
  }

  return(result.join(','));
}

export const subtractDays = (ISOdate: string | Date, days: number, formatToString = false): Date | string => {
  const date = ISOdate instanceof Date ? ISOdate : new Date(ISOdate);
  date.setDate(date.getDate() - days)

  return formatToString ? dateToYYYYmmDD(date) : date
}
