


























































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CompanyFactory from '@/factories/CompanyFactory';
import ICompanyConfig from '@/types/ICompanyConfig';
import CompanyService from '@/services/CompanyService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import {required} from '@/utils/validationRules';
import ICompanyConfigForms from '@/types/ICompanyConfigForms';
import {i18n} from '@/plugins/i18n';

@Component({
  name: 'CreateCompanyConfig',
})

export default class CreateCompanyConfig extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  formData: ICompanyConfig = CompanyFactory.toConfigCreate();
  private formFields: any = {};

  rules = {
    required: [required],
  };

  @Prop()
  form!: ICompanyConfigForms;

  mounted() {
    if(!!this.QueryParams.code && !!this.QueryParams.state){
      this.perform(this.QueryParams);
    }
  }

  get QueryParams() {
    return this.$route.query;
  }

  async perform(queryParams: any) {
    const code = queryParams.code;
    const id = queryParams.state;
    // @ts-ignore
    await this.$router.replace({'query': null});
    await this.exchange(id, code);
  }

  async exchange(id: number, code: string) {
    try {
      this.loading = true;
      await CompanyService.exchange(id, code);
      notify(
              NotificationTypes.success,
              i18n.t('SYSTEM_CONFIGURATION_AUTHORIZED'),
      );
      this.$emit('refresh');
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  getFormFields() {
    if (!!this.formData.clientTypeId)
      // @ts-ignore
      return this.form.types.find(item => item.typeId === this.formData.clientTypeId).formFields;
    else
      return [];
  }

  @Watch('dialog')
  prepareData(val: boolean) {
    if (val) {
      this.formData = CompanyFactory.toConfigCreate();
      // @ts-ignore
      this.$refs.userForm.resetValidation();
    } else {
      this.dialog = false;
    }
  }

  toggleVisibility() {
    this.dialog = false;
    // @ts-ignore
    this.$refs.userForm.resetValidation();
  }

  create() {
    // @ts-ignore
    if (this.$refs.userForm.validate()) {
      this.createConfig();
    }
  }

  marketplacesList(regions: any) {
    if (!this.formData.regionId) {
      return [];
    }
    return regions
        .find((element: any) => element.regionId === this.formData.regionId)
        .marketplaces;
  }

  async createConfig() {
    console.log({...this.formData, ...this.formFields})
    this.loading = true;
    try {
      await CompanyService.createCompanyConfig({...this.formData, ...this.formFields});
      this.$emit('create');
      this.toggleVisibility();
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
