var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_ADD'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_CONFIGURATION'))+" ")])]),_c('v-card-text',[_c('v-container',[_c('div',{staticClass:"pb-2"},[_c('v-icon',_vm._g({},_vm.on),[_vm._v(" mdi mdi-information-outline ")]),_vm._v(" "+_vm._s(_vm.$t('SYSTEM_COMPANY_CONFIG_ADD_INFO'))+" ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"userForm",attrs:{"lazy-validation":""}},[_c('v-autocomplete',{attrs:{"items":_vm.form.types,"item-text":"typeName","item-value":"typeId","label":_vm.$t('SYSTEM_TYPE'),"rules":_vm.rules.required},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.typeName))+" ")]}}]),model:{value:(_vm.formData.clientTypeId),callback:function ($$v) {_vm.$set(_vm.formData, "clientTypeId", $$v)},expression:"formData.clientTypeId"}}),_c('v-autocomplete',{attrs:{"items":_vm.form.regions,"item-text":"regionName","item-value":"regionId","label":_vm.$t('SYSTEM_REGION'),"rules":_vm.rules.required},on:{"change":function($event){_vm.formData.marketplaceIds = null}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.regionName))+" ")]}}]),model:{value:(_vm.formData.regionId),callback:function ($$v) {_vm.$set(_vm.formData, "regionId", $$v)},expression:"formData.regionId"}}),_c('v-autocomplete',{attrs:{"items":_vm.marketplacesList(_vm.form.regions),"item-text":"marketplaceName","item-value":"marketplaceId","label":_vm.$t('SYSTEM_MARKETPLACES'),"rules":_vm.rules.required,"disabled":!_vm.formData.regionId,"persistent-hint":"","hint":!_vm.formData.regionId ? _vm.$t('SYSTEM_SELECT_REGION') : '',"multiple":"","chips":"","small-chips":"","deletable-chips":"","counter":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.marketplaceName))+" ")]}}]),model:{value:(_vm.formData.marketplaceIds),callback:function ($$v) {_vm.$set(_vm.formData, "marketplaceIds", $$v)},expression:"formData.marketplaceIds"}}),_vm._l((_vm.getFormFields(_vm.formData.clientTypeId)),function(s){return _c('v-text-field',{attrs:{"label":_vm.$t(("SYSTEM_" + (s.toLocaleUpperCase()))),"rules":_vm.rules.required},model:{value:(_vm.formFields[s]),callback:function ($$v) {_vm.$set(_vm.formFields, s, $$v)},expression:"formFields[s]"}})})],2)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_SAVE'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"info","text":""},on:{"click":_vm.toggleVisibility}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_CANCEL'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }