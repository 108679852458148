

































import {Component, Vue} from 'vue-property-decorator';
import ICompanyConfig from '@/types/ICompanyConfig';
import {resolveError} from '@/utils/notifications';
import CompanyConfigTable from '@/components/Company/CompanyConfigTable.vue';
import CompanyService from '@/services/CompanyService';
import CreateCompanyConfig from '@/components/Company/CreateCompanyConfig.vue';

@Component({
  name: 'Config',
  components: {
    CompanyConfigTable,
    CreateCompanyConfig,
  },
})

export default class Config extends Vue {
  configList: ICompanyConfig[] = [];
  loading: boolean = false;

  created() {
    this.fetchConfigList();
  }

  async fetchConfigList() {
    this.loading = true;
    try {
      this.configList = await CompanyService.getCompanyConfig();
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  async revokeAuthorization(id: number) {
    this.loading = true;
    try {
      await CompanyService.revokeAuthorizationCompanyConfig(id);
      await this.fetchConfigList();
    } catch (e) {
      resolveError(e, 'fetch_data');
      this.loading = false;
    }
  }

  async authorize(item: ICompanyConfig) {
    this.loading = true;
    try {
      let url;
      if (item.clientTypeName === 'MARKETING'){
        url = await CompanyService.authorizeMarketing();
      } else{
        url = await CompanyService.authorizeSPAPI();
      }
      console.log(url)
      window.location.href = url;
    } catch (e) {
      resolveError(e, 'fetch_data');
      this.loading = false;
    }
  }
}

