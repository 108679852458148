






















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import CreateOrEditCompanyUser from '@/components/Company/CreateOrEditCompanyUser.vue';
import ICompanyConfig from '@/types/ICompanyConfig';
import {dateToYYYYmmDDHHmm} from '@/utils/dateTime';

@Component({
  name: 'CompanyConfigTable',
  components: {
    CreateOrEditCompanyUser,
  },
})
export default class CompanyConfigTable extends Vue {
  @Prop()
  data!: ICompanyConfig[];

  @Prop()
  loading!: boolean;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_TYPE',
      value: 'clientTypeName',
    },
    {
      text: 'SYSTEM_REGION',
      value: 'regionName',
    },
    {
      text: 'SYSTEM_CREATED_AT',
      value: 'createdAt',
    },
    {
      text: 'SYSTEM_AUTHORIZED',
      value: 'authorized',
    },
    {
      text: 'SYSTEM_AUTHORIZED_AT',
      value: 'authorizedAt',
    },
    {
      text: 'SYSTEM_ACTIONS',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  getColor(status: boolean) {
    return status ? 'green' : 'red';
  }

  async authorize(item: ICompanyConfig) {
    this.$emit('authorize', item);
  }

  async revokeConfig(id: number) {
    this.$emit('delete', id);
  }

  formatDate(dateTime: string): string {
    return dateTime ? dateToYYYYmmDDHHmm(dateTime) : '';
  }

};
