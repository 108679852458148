var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-3 pr-3"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.clientTypeName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.clientTypeName))+" ")]}},{key:"item.regionName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.regionName))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"item.authorized",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.authorized),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t(item.authorized ? 'SYSTEM_YES' : 'SYSTEM_NO'))+" ")])]}},{key:"item.authorizedAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.formatDate(item.authorizedAt))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.authorized)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue"},on:{"click":function($event){return _vm.authorize(item)}}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_CONFIGURATION_AUTHORIZE'))+" "),_c('v-icon',[_vm._v("mdi-account-arrow-left")])],1):_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"red"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_CONFIGURATION_REVOKE'))+" "),_c('v-icon',[_vm._v("mdi-backspace")])],1)]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_ARE_YOU_SURE_TO_REVOKE'))+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":item.loading,"loading":item.loading,"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.revokeConfig(item.configurationId)}}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_YES'))+" ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }